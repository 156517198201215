import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'

const IndexPage = () => {
  const queryData = useStaticQuery(graphql`
    query images {
      IMGgatsbyBlurred: file(relativePath: { eq: "gatsbyblurred.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGloadsOfCode: file(relativePath: { eq: "loads-of-code.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGcodingInPublic: file(relativePath: { eq: "coding-in-public.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGlaptopOnFloor: file(relativePath: { eq: "laptop-on-floor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGgatsbyTechLogo: file(relativePath: { eq: "gatsby-tech-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGfreelanceDevelopers: file(
        relativePath: { eq: "freelance-developers.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGdevelopmentScreens: file(
        relativePath: { eq: "development-two-screens.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      IMGdevelopersInMeeting: file(
        relativePath: { eq: "developers-in-meeting.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <section className="intro">
        <header>
          <h1>
            <a href="https://johnkavanagh.co.uk">
              Freelance Gatsby JS Developer
            </a>
          </h1>
          <p>How Gatsby JS is Making Freelance Web Development Easier</p>
          <ul className="actions">
            <li>
              <a href="#first" className="arrow scrolly">
                <span className="label">Next</span>
              </a>
            </li>
          </ul>
        </header>
        <div className="content">
          <span className="image fill" data-position="center">
            <Img
              fluid={queryData.IMGgatsbyBlurred.childImageSharp.fluid}
              alt="Blurred Gastby JS logo"
            />
          </span>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>Introduction</h2>
        </header>
        <div className="content">
          <p>
            Freelance web development is an easy and rewarding career. Freelance
            web developers can work from anywhere globally, set their own
            schedules, and take on projects that they are interested in.
          </p>
          <p>
            Freelance web development has been around for a while now, but
            Gatsby JS makes it easier than ever before to succeed as a freelance
            web developer. Freelancers who use{' '}
            <a href="https://www.gatsbyjs.com/">Gatsby JS</a> will find no need
            to worry about hosting and server management.
          </p>
          <p>
            Freelancers also do not need any knowledge of web design or
            programming to freelance with Gatsby JS. Any developer who has some
            experience in JavaScript can freelance as a{' '}
            <a href="https://johnkavanagh.co.uk/about/" className="inline">
              freelance front-end (UI) developer
            </a>{' '}
            by using Gatsby JS.
          </p>
          <span className="image main">
            <Img
              fluid={queryData.IMGloadsOfCode.childImageSharp.fluid}
              alt="Photograph of a sreen full of JavaScript code"
            />
          </span>
          <p>
            This article will discuss freelance web development, its benefits,
            and the skills required to become a{' '}
            <a href="https://johnkavanagh.co.uk/" className="inline">
              freelance web developer
            </a>
            . The article will also cover Gatsby JS, its features, and how
            freelance front-end (UI) developers can excel in their careers using
            Gatsby JS.
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>What Is Freelance Web Development?</h2>
        </header>
        <div className="content">
          <p>
            Freelance web development is where the{' '}
            <a href="https://johnkavanagh.co.uk/" className="inline">
              freelance developer
            </a>{' '}
            creates websites, applications, and various other digital products.
            This freelance job can be done by anyone who has basic coding
            knowledge (HTML, CSS, etc.).
          </p>
          <p>
            Freelance developers also work remotely in which they do not need to
            go to an office every day but rather perform their work from the
            comfort of their homes and freelance from different locations around
            the world.
          </p>
          <p>
            Some freelance developers do freelance work for a living, and then
            some freelance developers only take freelance work when they need
            the cash flow.
          </p>
          <p>
            <a href="https://johnkavanagh.co.uk/contact/" className="inline">
              Freelance web developers
            </a>{' '}
            can make a freelance career by working for themselves and picking
            projects that interest them or joining a freelance agency and
            working on projects for different clients.
          </p>

          <h3>What Are the Benefits of Freelance Web Development?</h3>
          <p>
            Freelance web developers have more control over their own time and
            choose when they want to work. Freelancers enjoy many benefits while
            working from the comfort of their homes.
          </p>

          <span className="image main">
            <Img
              fluid={queryData.IMGlaptopOnFloor.childImageSharp.fluid}
              alt="Photograph of a laptop on the floor surrounded by other items including a blanket and a camera"
            />
          </span>

          <dl>
            <dt>
              <h4>Independent Working</h4>
            </dt>
            <dd>
              <p>
                Since any company does not tie down freelance developers, they
                can easily move to other projects or companies if they are
                unhappy with their current situation.
              </p>
              <p>
                <a href="https://johnkavanagh.co.uk/about/" className="inline">
                  Freelance developers
                </a>{' '}
                are their own bosses, and they can set their rates for the
                services that they offer. Company rates do not restrict them.
              </p>
            </dd>
            <dt>
              <h4>No Borders</h4>
            </dt>
            <dd>
              <p>
                The biggest benefit of freelance web development is that it
                offers a lot of freedom. Freelance developers can work from
                anywhere globally, and they are not limited to working for a
                certain company.
              </p>
              <span className="image main">
                <Img
                  fluid={queryData.IMGcodingInPublic.childImageSharp.fluid}
                  alt="Photograph of a lady working on her laptop on a balcony outside a coffee shop"
                />
              </span>
            </dd>
            <dt>
              <h4>Flexible Timings</h4>
            </dt>
            <dd>
              <p>
                Freelance developers are more flexible with freelance web
                development - freelance developers can freelance whenever they
                want, which means they are not bound by the hours their company
                sets for them.
              </p>
            </dd>
          </dl>
        </div>
      </section>

      <section>
        <header>
          <h2>What Is Gatsby JS?</h2>
        </header>
        <div className="content">
          <span className="image main">
            <Img
              fluid={queryData.IMGgatsbyTechLogo.childImageSharp.fluid}
              alt="Gatsby JS logo in a technical drawing style with outlines and keylines"
            />
          </span>
          <p>
            Gatsby is a React-based static site generator that helps freelance
            web developers to build websites quickly and easily. Gatsby compiles
            code written in these languages into static files hosted on any web
            server.
          </p>
          <p>
            Gatsby has been around for a while now, and freelance front-end (UI)
            developers can use it to build{' '}
            <a href="https://johnkavanagh.co.uk/projects/" className="inline">
              high-quality dynamic sites
            </a>{' '}
            without having any knowledge of web design or programming.
          </p>
          <p>
            Gatsby has a framework that freelance web developers can use to
            build React applications. It also contains pre-made plugins for
            various services that freelance web developers will need to build
            their applications.
          </p>
          <p>
            Gatsby is modular, and freelance front-end (UI) developers can plug
            in any third-party plugin they need. Most freelance web developers
            find it easy to build sites with Gatsby because of the high-quality
            plugins available for freelance front-end (UI) developers.
          </p>
          <h3>What Are the Features of Gatsby JS?</h3>
          <p>
            There is a lot of hype around Gatsby JS, and freelance front-end
            (UI) developers find it easy to use Gatsby because of its amazing
            features.
          </p>

          <dl>
            <dt>
              <h4>Work Done Quickly</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS helps freelance web developers become more productive
                and saves them time building websites. It has a fast development
                cycle that helps freelance web developers to get their work done
                quickly.
              </p>
            </dd>

            <dt>
              <h4>Enhanced Functionality</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS uses static files to build dynamic sites, and
                freelance web developers find it easy to use Gatsby because of
                its flexibility. It has a plugin system that helps freelance web
                developers extend their functionality and build any type of site
                they want.
              </p>
            </dd>
            <dt>
              <h4>Optimised Websites</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS has a lot of plugins that freelance web developers can
                use to optimise their sites. It also compiles code into static
                files, and freelance web developers can optimise their sites
                using the minified plugin.
              </p>
            </dd>
            <dt>
              <h4>Increased Usability</h4>
            </dt>
            <dd>
              <p>
                A website built with Gatsby JS is more user-friendly and helps
                freelance web developers to get better search engine rankings.
                It optimises websites for search engines and makes it easier for
                freelance web developers to get more traffic.
              </p>
            </dd>
            <dt>
              <h4>Easier Maintenance</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS is easy to maintain, and freelance web developers can
                keep their websites updated by using the updated plugin. It also
                helps freelance front-end (UI) developers to save time when
                building websites.
              </p>
            </dd>
            <dt>
              <h4>Top Security</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS is secure, and freelance web developers can keep their
                sites safe from hackers by using the security plugin. This helps
                freelance web developers to protect their sites from malicious
                attacks and keeps them safe from data theft.
              </p>
            </dd>
            <dt>
              <h4>Easy npm Integration</h4>
            </dt>
            <dd>
              <p>
                Gatsby JS integrates easily with npm, and freelance web
                developers can install any package that they need from the npm
                repository. This makes it easy for freelance web developers to
                use any third-party plugin that they need.
              </p>
            </dd>
          </dl>
        </div>
      </section>

      <section>
        <header>
          <h2>
            What Are the Skills Required to Become a Freelance Web Developer?
          </h2>
        </header>
        <div className="content">
          <p>
            The skills required to become a freelance web developer are basic
            coding knowledge (HTML, CSS, JavaScript), an understanding of the
            web development process, and strong problem-solving skills.
          </p>
          <p>
            A{' '}
            <a href="https://johnkavanagh.co.uk/about/" className="inline">
              freelance web developer
            </a>{' '}
            should be able to work independently as well as part of a team. A
            freelance web developer should also demonstrate excellent
            communication skills and a great sense of commitment to their work.
          </p>
          <span className="image main">
            <Img
              fluid={queryData.IMGfreelanceDevelopers.childImageSharp.fluid}
              alt="Photograph of two male freelance developers sitting at their computers"
            />
          </span>
        </div>
      </section>

      <section>
        <header>
          <h2>How Is Gatsby JS Helping Freelance Web Developers?</h2>
        </header>
        <div className="content">
          <p>
            Gatsby JS is making freelance web development an easier career, and
            freelance front-end (UI) developers looking for freelance jobs will
            find it easy to get hired if they know how to use Gatsby JS.
          </p>
          <p>
            This is because freelance web developers using Gatsby JS have a
            competitive edge over freelance web developers who don't know how to
            use it. Freelance web developers can use Gatsby JS to build their
            own applications and showcase them as part of their{' '}
            <a href="https://johnkavanagh.co.uk/projects/" className="inline">
              freelance portfolio
            </a>
            .
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>How to Get Started as a Freelance Web Developer?</h2>
        </header>
        <div className="content">
          <p>
            Freelance web development is a lucrative option that can create a
            stable revenue stream for years to come. As a freelance web
            developer, you'll create websites for multiple businesses and
            organisations.
          </p>
          <p>
            However, before you can start making money, you have to get clients
            first. When you have a few clients lined up and ready to pay your
            rates, it's time to set up your freelance web development business
            properly. This takes some time and effort, but it will pay off in
            the long run when you have a fully polished business foundation.
          </p>
          <p>
            Many people would like to become freelance web developers, but they
            don't know where to start. The following tips explain the process of
            getting started as a freelance web developer and becoming a
            successful one.
          </p>

          <ol>
            <li>
              <h3>1. Get Experience</h3>
              <p>
                Getting experience in freelance web development is not an
                overnight process. You’ll have to invest a lot of time and
                effort into learning freelance web development before you can
                start applying for freelance jobs.
              </p>
            </li>

            <li>
              <h3>2. Build Your Portfolio</h3>
              <p>
                Before starting working as a freelance web developer, you have
                to build a portfolio. You can build your portfolio by creating
                websites using Gatsby JS and showcasing them as{' '}
                <a
                  href="https://johnkavanagh.co.uk/projects/"
                  className="inline"
                >
                  freelance web developer portfolio
                </a>{' '}
                samples.
              </p>
            </li>
            <li>
              <h3>3. Get Certified</h3>
              <p>
                Getting freelance jobs is easier if you’re certified, and
                freelance web developers who are certified have an advantage
                over freelance web developers who aren’t. You can get freelance
                jobs by getting certification from the Gatsby JS Certification
                Course.
              </p>
            </li>
            <li>
              <h3>4. Find Clients</h3>
              <p>
                Freelance web developers can find freelance clients by looking
                for freelance jobs on freelance job boards (Fiverr, Upwork) or
                talking to potential clients directly. You can also work for a
                company that employs freelance web developers to work on their
                projects.
              </p>
            </li>
            <li>
              <h3>5. Deliver Quality Work</h3>
              <p>
                Freelancers must deliver{' '}
                <a
                  href="https://johnkavanagh.co.uk/projects/"
                  className="inline"
                >
                  quality work
                </a>{' '}
                to their clients that are on time. If you do not deliver quality
                work, you will lose your reputation, leading to you getting
                fewer jobs or even none at all.
              </p>
              <p>
                Freelance web developers can build{' '}
                <a href="https://johnkavanagh.co.uk/" className="inline">
                  amazing websites
                </a>{' '}
                using Gatsby JS and deliver them to their clients on time.
                Gatsby JS will allow freelance web developers to build websites
                fast and deliver the final product without delays.
              </p>
            </li>
            <li>
              <h3>6. Network</h3>
              <p>
                Freelance web developers have to keep their websites updated and
                active to attract more clients. The best way freelance web
                developers can attract new clients is by networking with
                freelance web developers in their industry.
              </p>
              <p>
                You can network with freelance web developers by joining
                freelance job boards and freelance communities. You can also
                connect with freelance web developers on social media platforms
                like Twitter and Facebook.
              </p>
            </li>
            <li>
              <h3>7. Stay Organised</h3>
              <p>
                Freelance web developers need to stay organised when they're
                working for multiple clients simultaneously. This is because
                freelance web developers will lose track of their work if
                they're disorganised.
              </p>
              <p>
                One way freelance web developers can keep track of their
                freelance work is by using management tools. You can use
                freelance management tools to track your progress, generate
                invoices for your clients, and manage team members.
              </p>
            </li>
          </ol>

          <span className="image main">
            <Img
              fluid={queryData.IMGdevelopmentScreens.childImageSharp.fluid}
              alt="Photograph of a web development set up with two screens, an iPhone, and a set of wireless headphones"
            />
          </span>
        </div>
      </section>

      <section>
        <header>
          <h2>What Are the Three Main Types of Web Development?</h2>
        </header>
        <div className="content">
          <p>
            There are three main types of freelance web development, and you
            need to be familiar with these different types in order to succeed
            as a freelance web developer.
          </p>

          <ol>
            <li>
              <h3>Front-End Web Development</h3>
              <p>
                <a
                  href="https://johnkavanagh.co.uk/services/"
                  className="inline"
                >
                  Front-end web development
                </a>{' '}
                involves developing front-end code for websites and
                applications. This means freelance web developers need to know
                how to design websites or applications along with their
                elements.
              </p>

              <p>
                Front-end web developers can use Gatsby JS to create the
                front-end code for websites and applications that run on the
                web.
              </p>
              <p>
                Front-end web development is high in demand, and freelance web
                developers can easily find freelance jobs for front-end
                development.
              </p>
            </li>
            <li>
              <h3>Back-End Web Development</h3>
              <p>
                Back-end freelance web development involves making databases,
                setting up back-end code, and maintaining the required software
                for front-end freelance web development.
              </p>
              <p>
                There are many jobs available for back-end web development, and
                freelance web developers can easily get work as back-end web
                developers.
              </p>
            </li>
            <li>
              <h3>Full-Stack Web Development</h3>
              <p>
                Full-stack freelance web development involves both back-end web
                development and front-end web development.
              </p>
              <p>
                Every web developer will need to know basic information about
                freelance web development and know what type they want to
                specialise in.
              </p>
              <p>
                Full-stack freelance web development is the most prestigious
                freelance type. This freelance type is also the most difficult
                to acquire, but those who become full-stack web developers will
                charge higher rates.
              </p>
            </li>
          </ol>

          <span className="image main">
            <Img
              fluid={queryData.IMGdevelopersInMeeting.childImageSharp.fluid}
              alt="Black and white photograph of two devleopers in a meeting with a laptop"
            />
          </span>
        </div>
      </section>

      <section>
        <header>
          <h2>How Much Do Freelance Web Developers Make?</h2>
        </header>
        <div className="content">
          <p>
            Freelance web developers can fairly easily earn more than £40,000
            each year. This freelance type can make more if you are highly
            skilled and work for high-profile companies. More senior and
            experienced freelance developers can often command higher amounts,
            even exceeeding £100,000 or more year year.
          </p>
          <p>
            The earnings depend on how skilled freelance web developers are and
            what freelance type they specialise in. The rates vary based on
            several factors, such as work experience, location, client type, and
            client requirements.
          </p>
          <p>
            However, freelance web developers with two years of experience
            should be comfortably earning £40,000 or more. Freelance web
            developers who have more experience command more.
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Conculsion</h2>
        </header>
        <div className="content">
          <p>
            Freelance web development is a well-paying career, and web
            developers can make a living from their freelance life. To succeed
            as a freelance web developer, you need to be skilled and market
            yourself. Gatsby JS is helping freelance web developers to excel in
            their careers by making front-end web development much easier,
            faster, and effective. Freelance web developers can use Gatsby JS to
            create productive and user-friendly websites. It makes it much
            simpler for freelance web developers to meet clients' requirements
            and earn a significant amount of money.
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>John Kavanagh</h2>
        </header>
        <div className="content">
          <p>
            <a href="https://johnkavanagh.co.uk/">
              <strong>John Kavanagh</strong>
            </a>{' '}
            is a very highly-experienced and talented developer, especially
            within front-end development, and full-solution development using
            Gatsby JS. He is a{' '}
            <a href="https://github.com/search?q=%22johnkavanagh.co.uk%22&type=commits">
              previous contributor to the codebase
            </a>
            , a Gatsby Creator, and has produced{' '}
            <a href="https://johnkavanagh.co.uk/projects/">
              hundreds of websites
            </a>{' '}
            using the platform, ranging from simple online identities to huge
            ecommerce platforms.
          </p>
          <ul className="actions">
            <li>
              <a
                href="https://johnkavanagh.co.uk/about/"
                className="button primary large"
              >
                Read about John
              </a>
            </li>
            <li>
              <a
                href="https://johnkavanagh.co.uk/contact/"
                className="button large"
              >
                Contact John
              </a>
            </li>
          </ul>
        </div>
      </section>

      <div className="copyright">
        &copy; {new Date().getFullYear()} . All rights reserved.
      </div>
    </Layout>
  )
}

export default IndexPage
